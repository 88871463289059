import * as React from "react";
import "./SectionThree.scss";
import PrincipalImage from "../../images/illustrations/talents.administration.png";
import MessagesIcon from "../../images/icons/messages.inline.svg";
import BirthdayIcon from "../../images/icons/birthday.inline.svg";
import ScheduleIcon from "../../images/icons/schedule.inline.svg";
import CredentialIcon from "../../images/icons/credential.inline.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SectionThree = () => {
	return (
		<div className="section-three">
			<div className="dots"></div>
			<div className="circle lg-circle">
				<div className="circle sm-circle"></div>
			</div>
			<div className="title">
				<h2>
					Administra los <label>talentos</label> del equipo
				</h2>
			</div>
			<div className="subtitle">
				<h3>Organiza tus talentos. Conoce nuevos y mantén los que ya están.</h3>
			</div>
			<div className="feature-specifications">
				<div className="left">
					<div className="feature">
						<div className="image">
							<MessagesIcon />
						</div>
						<div className="text">
							<label>Mensajería</label>
							<p>
								¿Necesitás que las consultas sean respondidas rápidamente?
								Genera grupos de trabajo y gestiona las comunicaciones de manera
								directa
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<BirthdayIcon />
						</div>
						<div className="text">
							<label>Cumpleaños</label>
							<p>
								Enterate de los cumpleaños de los colaboradores y envíales un
								saludo
							</p>
						</div>
					</div>
				</div>
				<div className="middle">
					<LazyLoadImage
						alt="imagen 1"
						src={PrincipalImage}
						effect="blur"
					/>
				</div>
				<div className="right">
					<div className="feature">
						<div className="image">
							<ScheduleIcon />
						</div>
						<div className="text">
							<label>Agenda</label>
							<p>
								¿Querés comunicarte rápidamente con los colaboradores? Tené la
								información de todos tus talentos en un solo lugar
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<CredentialIcon />
						</div>
						<div className="text">
							<label>Credencial</label>
							<p>
								¿Necesitás tener los datos del colaborador a mano? Digitaliza la
								identificación de cada uno con los datos que necesites
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionThree;
