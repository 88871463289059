import * as React from "react";
import "./SectionFour.scss";
import PrincipalImage from "../../images/illustrations/metrics.administration.png";
import SegmentsIcon from "../../images/icons/segments.inline.svg";
import BellIcon from "../../images/icons/bell.inline.svg";
import MetricIcon from "../../images/icons/metrics.inline.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SectionFour = () => {
	return (
		<div className="section-four">
			<div className="circle lg-circle">
				<div className="circle sm-circle"></div>
			</div>
			<div className="title">
				<h2>
					Adquiere <label>información</label> y <label>mide</label> los
					resultados
				</h2>
			</div>
			<div className="subtitle">
				<h3>
					Agiliza tu contenido. Organiza la información. Mide los resultados.
				</h3>
			</div>
			<div className="feature-specifications">
				<div className="left">
					<div className="feature">
						<div className="image">
							<SegmentsIcon />
						</div>
						<div className="text">
							<label>Segmentación de contenido</label>
							<p>
								Segmenta la información, comunica todos los contenidos de tu
								empresa según sector, sucursal, área, entre otros.
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<BellIcon />
						</div>
						<div className="text">
							<label>Gestión de notificaciones</label>
							<p>
								Para que los talentos se enteren de todo lo importante en la
								empresa, envía notificaciones a cualquier miembro o segmento
								definido
							</p>
						</div>
					</div>
					<div className="feature">
						<div className="image">
							<MetricIcon />
						</div>
						<div className="text">
							<label>Métricas</label>
							<p>
								Medí el éxito del contenido para aumentar la efectividad e
								impacto en las comunicaciones
							</p>
						</div>
					</div>
				</div>
				<div className="middle">
					<LazyLoadImage
						alt="imagen 1"
						src={PrincipalImage}
						effect="blur"
					/>
				</div>
			</div>
		</div>
	);
};

export default SectionFour;
