import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper-bundle.css";

import "./SwiperClients.scss";

// import required modules
//import { Autoplay, Pagination, Navigation } from "swiper";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

import Sinteplast from "../../images/clients/sinteplast.png";
import Marval from "../../images/clients/marval.png";
import Buhl from "../../images/clients/buhl.png";
import Technosoftware from "../../images/clients/technosoftware.png";
import Goiar from "../../images/clients/goiar.png";
import Medmun from "../../images/clients/medmun.png";
import Panamerican from "../../images/clients/panamericansilver.png";
import Aerosan from "../../images/clients/aerosan.png";
import Torneos from "../../images/clients/torneos.png";
import Shopgallery from "../../images/clients/shopgallery.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SwiperClients = () => {
  return (
    <section id="clients">
      <div className="clients-swiper">
        <div className="title">
          <p>Ellos confiaron en nosotros...</p>
        </div>
        <Swiper
          slidesPerView={5}
          spaceBetween={10}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={false}
          pagination={{
            clickable: true,
          }}
          loop={true}
          breakpoints={{
            // when window width is >= 1024px
            1920: {
              slidesPerView: 5,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
            },
            // when window width is >= 850px
            850: {
              slidesPerView: 3,
            },
            // when window width is >= 375px
            320: {
              slidesPerView: 1,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="clients-swiper"
        >
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Sinteplast} alt="Sinteplast" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Marval} alt="Marval" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Buhl} alt="Buhl" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Technosoftware} alt="Technosoftware" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Goiar} alt="Goiar" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Medmun} alt="Medmun" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Panamerican} alt="Panamerican" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Aerosan} alt="Aerosan" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Torneos} alt="Torneos" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="client">
              <LazyLoadImage src={Shopgallery} alt="Shopgallery" loading="lazy" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default SwiperClients;
