import * as React from "react";
import Hero from "../components/Hero/Hero";
import "swiper/swiper.scss";
import ContactForm from "../components/ContactForm/ContactForm";
import SectionOne from "../components/SectionOne/SectionOne";
import SectionTwo from "../components/SectionTwo/SectionTwo";
import SectionThree from "../components/SectionThree/SectionThree";
import SectionFour from "../components/SectionFour/SectionFour";
import SectionFive from "../components/SectionFive/SectionFive";
import SwiperClients from "../components/SwiperClients/SwiperClients";
import FixedButton from "../components/FixedButton/FixedButton";
import 'react-lazy-load-image-component/src/effects/blur.css';

const IndexPage = () => {
  return (
    <>
      <Hero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SwiperClients />
      <ContactForm />
      <FixedButton isHomePage={true} />
    </>
  );
};

export default IndexPage;
